/* eslint-disable import/namespace */
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../root-reducer/rootReducer";
import { persistStore } from "redux-persist";

// export default configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistore = persistStore(store);
